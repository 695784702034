import React from 'react';
import Helmet from 'react-helmet';

import { Lframe } from '@latitude/lframe';
import { BREAKPOINT } from '@latitude/core/utils/constants';

import HeroBranded from '../../components/HeroBranded/HeroBranded';
import Layout from '../../components/layout';
import WhitepapersMediaSection from '../../components/Media/WhitepapersMediaSection';
import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';
import PageData from './data/latitude-latest.json';
import imageHero from './images/lshape-whitepaper.webp';

const WhitePapersPage = props => {
  return (
    <Layout location={props.location}>
      <main>
        <Helmet>
          <title>{PageData.meta.title}</title>
          <meta name="description" content={PageData.meta.description} />
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/about-us/whitepapers/"
          />
        </Helmet>

        <Lframe bgImage={imageHero} />
        <HeroBranded
          className="bg-lightest"
          title={PageData.hero.title}
          text={PageData.hero.text}
          breadcrumbs={PageData.hero.breadcrumbs}
          css={`
            @media (min-width: ${BREAKPOINT.LG}) {
              margin-top: 50px;
            }
            @media (min-width: ${BREAKPOINT.MD}) {
              .HeroTitle {
                padding-top: 80px;
              }
              .HeroText {
                padding-bottom: 30px;
              }
            }
          `}
        />
        <StickyNavigationBranded
          items={PageData.stickyNavigation}
          offsetElem="[data-sticky-navigation-offset]"
          trackId="whitepapers-stickynav"
        />
        <WhitepapersMediaSection />
      </main>
    </Layout>
  );
};

export default WhitePapersPage;
